// vendors
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Container, Wrapper, Stack, Title } from './DetailContainer.styles';

const DetailContainer = ({ children }) => {
  return (
    <Container>
      <Wrapper>
        <div
          itemScope
          itemType='https://schema.org/HomeAndConstructionBusiness'
        >
          <Title itemProp='name'>Permafil</Title>

          <Stack>{children}</Stack>
        </div>
      </Wrapper>
    </Container>
  );
};

DetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DetailContainer;
