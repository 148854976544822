import styled from 'styled-components';
import { rem } from 'polished';

import { greaterThan, lessThan } from '@utils/mediaQuery';
import breakpointsRange from '@utils/breakpointsRange';
import breakpoints from '@styles/breakpoints';

export const layoutBreakpoint = 960;

export const Container = styled.div`
  min-width: calc(50% - 40px);
  padding-right: 24px;
  padding-left: max(calc(50% - 1408px / 2), 24px);

  background-color: ${({ theme }) => theme.colors.veryLightPink};
`;

export const Wrapper = styled.div`
  margin-top: ${rem(128, 22)};
  margin-bottom: ${rem(60, 22)};

  ${lessThan(layoutBreakpoint)} {
    margin-top: ${rem(60, 22)};

    text-align: center;
  }
`;

export const Icon = styled.img`
  min-width: 260px;

  ${breakpointsRange(
    [{ prop: 'maxWidth', sizes: [208, 300] }],
    breakpoints.spacings
  )};

  margin-top: ${rem(60, 16)};

  ${greaterThan(layoutBreakpoint)} {
    margin-top: ${rem(96, 22)};
  }
`;
