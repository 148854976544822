import React from 'react';

const IconArrow = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 35.206 35.303'
    {...props}
  >
    <g transform='translate(0 .652)'>
      <path
        d='M-14792.689-965.505l14.605 17-14.605 17'
        transform='translate(14811.972 965.505)'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M0 0L34 0'
        transform='translate(0 17.182)'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
      />
    </g>
  </svg>
);

export default IconArrow;
