// vendors
import React from 'react';
import PropTypes from 'prop-types';

// images
import vectorContact from '@images/vectorContact.svg';

// styles
import { Container, Wrapper, Icon } from './IconContainer.styles';

const IconContainer = ({ children }) => {
  return (
    <Container>
      <Wrapper>
        {children}

        <Icon src={vectorContact} />
      </Wrapper>
    </Container>
  );
};

IconContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconContainer;
