// vendors
import React from 'react';
import { hideVisually } from 'polished';

// utils
import { lessThan } from '@utils/mediaQuery';

// images
import IconArrow from '@images/IconArrow';

// styles
import { h1Style, linkStyle, unstyledLinkStyle } from '@styles/global';
import {
  Stack,
  List,
  Line,
  SubTitle,
  AddressSection,
  DepartmentSection,
  CTASection,
} from '@views/ContactPageView/DetailContainer/DetailContainer.styles';

// components
import Layout from '@components/Layout';
import SideBarLayout from '@components/SideBarLayout';
import Button from '@components/Button';

// views
import IconContainer from '@views/ContactPageView/IconContainer';
import DetailContainer from '@views/ContactPageView/DetailContainer';
import SEO from '@src/components/SEO';

const NousContacterPage = () => {
  const langLinks = [{ langKey: 'en', href: '/en/contact-us' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Nous contacter'
        description='Permafil, une entreprise québécoise spécialisée dans les espaces de rangements commerciaux et domestiques. Contactez-nous pour une soumission ou complétez le formulaire de commande et un membre de notre équipe communiquera avec vous.'
      />

      <SideBarLayout>
        <IconContainer>
          <h1 css={h1Style}>Nous contacter</h1>
        </IconContainer>

        <DetailContainer>
          <AddressSection>
            <SubTitle css={hideVisually}>Notre adresse</SubTitle>

            <p>
              <a
                itemProp='address'
                itemScope
                itemType='http://schema.org/PostalAddress'
                href='https://goo.gl/maps/W6zUZBLcL1F1h8id7'
                target='_blank'
                rel='noreferrer'
                css={unstyledLinkStyle}
              >
                <span itemProp='streetAddress'>225, rue Industrielle</span>
                ,&nbsp;
                <br />
                <span itemProp='addressLocality'>Sainte-Marguerite</span>
                ,&nbsp;
                <span itemProp='addressRegion'>Quebec</span>
                ,&nbsp;
                <br />
                <span itemProp='postalCode'>G0S 2X0</span>
              </a>
            </p>
          </AddressSection>

          <DepartmentSection itemProp='department'>
            <SubTitle itemProp='name'>Administration</SubTitle>

            <List>
              <Line>
                <span>Tél: </span>
                <a href='tel:+18004631434' itemProp='telephone' css={linkStyle}>
                  1 800 463-1434
                </a>
              </Line>

              <Line>
                <span>Télec: </span>
                <span itemProp='faxNumber'>418 624-3338</span>
              </Line>

              <Line>
                <span>Courriel: </span>
                <a
                  href='mailto:info@permafil.com'
                  itemProp='email'
                  css={linkStyle}
                >
                  info@permafil.com
                </a>
              </Line>
            </List>
          </DepartmentSection>

          <DepartmentSection itemProp='department'>
            <SubTitle itemProp='name'>Bureau des ventes</SubTitle>

            <List>
              <Line>
                <span>Tél: </span>
                <a href='tel:+18004631434' itemProp='telephone' css={linkStyle}>
                  1 800 463-1434
                </a>
              </Line>

              <Line>
                <span>Télec: </span>
                <span itemProp='faxNumber'>418 624-3338</span>
              </Line>

              <Line>
                <span>Courriel: </span>
                <a
                  href='mailto:ventes@permafil.com'
                  itemProp='email'
                  css={linkStyle}
                >
                  ventes@permafil.com
                </a>
              </Line>
            </List>
          </DepartmentSection>

          <CTASection>
            <SubTitle>Trouver nos produits</SubTitle>

            <Stack
              css={`
                display: flex;
                flex-direction: column;

                > * {
                  max-width: 495px;
                }
              `}
            >
              <Button
                primary
                outlined
                renderIcon={<IconArrow />}
                to='/particuliers#detaillants'
                tag='link'
              >
                Voir nos distributeurs
              </Button>

              <Button
                primary
                renderIcon={<IconArrow />}
                to='/formulaire-de-contact'
                tag='link'
                css={`
                  ${lessThan(480)} {
                    margin-left: -24px;
                    margin-right: -24px;
                  }
                `}
              >
                Formulaire de prise de commandes
              </Button>

              <Button
                primary
                renderIcon={<IconArrow />}
                to='/formulaire-de-contact'
                tag='link'
                css={`
                  ${lessThan(480)} {
                    margin-left: -24px;
                    margin-right: -24px;
                  }
                `}
              >
                Demande d&apos;informations
              </Button>
            </Stack>
          </CTASection>
        </DetailContainer>
      </SideBarLayout>
    </Layout>
  );
};

NousContacterPage.propTypes = {};

export default NousContacterPage;
